<template>
  <v-dialog
      v-model="dialog"
      max-width="600"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} Academic Class</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="academic_class.title"
                  :error="$v.academic_class.title.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Title <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.academic_class.title.$error">Name is required</span>
              <span class="text-danger" v-if="errors.title" >* {{ errors.title[0] }}</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                  v-model="academic_class.no_of_seats"
                  :error="$v.academic_class.no_of_seats.$error || !$v.academic_class.no_of_seats.numeric"
                  outlined
                  dense
              >
                <template v-slot:label>
                  No Of Seats <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.academic_class.no_of_seats.$error">No Of Seats is required</span>
              <span class="text-danger" v-if="errors.no_of_seats" >* {{ errors.no_of_seats[0] }}</span>
            </v-col>

            <v-col cols="12" md="12">
              <v-select
                  v-model="academic_class.service_id"
                  :error="$v.academic_class.service_id.$error"
                  :items="services"
                  item-text="title"
                  item-value="id"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Service <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.academic_class.service_id.$error">Service is required</span>
              <span class="text-danger" v-if="errors.service_id" >* {{ errors.service_id[0] }}</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-menu
              ref="menuStartDate"
              v-model="menuStartDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
              >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="academic_class.start_date"
                    :error="$v.academic_class.start_date.$error"
                    clearable
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                >
                  <template v-slot:label>
                    Start Date <span class="text-danger">*</span>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                  v-model="academic_class.start_date"
                  outlined
                  dense
                  no-title
                  :min="currentDate"
                  @input="menuStartDate = false"
              >
              </v-date-picker>
              </v-menu>
              <span class="text-danger" v-if="$v.academic_class.start_date.$error">Type is required</span>
              <span class="text-danger" v-if="errors.start_date" >* {{ errors.start_date[0] }}</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-menu
                  ref="menuEndDate"
                  v-model="menuEndDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="academic_class.end_date"
                      :error="$v.academic_class.end_date.$error"
                      readonly
                      outlined
                      clearable
                      dense
                      v-bind="attrs"
                      v-on="on"
                  >
                    <template v-slot:label>
                      End Date <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                    v-model="academic_class.end_date"
                    outlined
                    dense
                    no-title
                    :min="currentDate"
                    @input="menuEndDate = false"
                >
                </v-date-picker>
              </v-menu>
              <span class="text-danger" v-if="$v.academic_class.end_date.$error">End Date is required</span>
              <span class="text-danger" v-if="errors.end_date" >* {{ errors.end_date[0] }}</span>
            </v-col>

            <v-col cols="12" md="6">
              Start Time <span class="text-danger">*</span>
              <v-text-field  label="Start Time"   v-model="academic_class.start_time"  :error="$v.academic_class.start_time.$error" type="time" outlined dense>
              </v-text-field>
              <span class="text-danger" v-if="errors.start_time" >**{{errors.start_time[0]}}</span>
              <span class="text-danger" v-if="$v.academic_class.start_time.$error">Please Select Start Time</span>
            </v-col>

            <v-col cols="12" md="6">
              End Time <span class="text-danger">*</span>
              <v-text-field label="End Time"  v-model="academic_class.end_time"  :error="$v.academic_class.end_time.$error" type="time" outlined dense>
              </v-text-field>
              <span class="text-danger" v-if="errors.end_time" >**{{errors.end_time[0]}}</span>
              <span class="text-danger" v-if="$v.academic_class.end_time.$error">Please Select End Time</span>
            </v-col>

            <v-col cols="12" md="12">
              <v-select
                  v-model="academic_class.status"
                  :error="$v.academic_class.status.$error"
                  :items="statusType"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Status <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.academic_class.status.$error">Status is required</span>
              <span class="text-danger" v-if="errors.status" >* {{ errors.status[0] }}</span>
            </v-col>

            <v-col cols="12">
              Description <span class="text-danger">*</span>
              <ckeditor
                  :config="editorConfig"
                  v-model="academic_class.description"
              >
              </ckeditor>
              <span class="text-danger" v-if="$v.academic_class.description.$error">Description Field is required</span>
              <span class="text-danger" v-if="errors.description" >* {{ errors.description[0] }}</span>
            </v-col>

            <v-col cols="12" md="6">
              Status
              <v-switch
                  v-model="academic_class.is_active"
                  :label="academic_class.is_active ? 'Active' : 'Inactive'"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="mr-2" text dark
            medium
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn v-if="checkIsAccessible('academic-class', 'create') || checkIsAccessible('academic-class', 'edit')"
            class="btn btn-primary"
            medium
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, numeric } from "vuelidate/lib/validators";
import ClassService from "@/services/academic-class/AcademicClassService";
import ServiceService from "@/services/service/ServiceService";
import VueTimepicker from "vue2-timepicker";
import 'vue2-timepicker/dist/VueTimepicker.css';

const academic_class = new ClassService();
const service = new ServiceService();

export default {
  name: "CreateAndUpdate",
  components: {
    VueTimepicker
  },
  data() {
    return {
      title: '',
      edit: false,
      dialog: false,
      loading: false,
      errors: [],
      services: [],
      menuStartDate: false,
      menuEndDate: false,
      time_format:'hh:mm',
      currentDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      statusType: [
        { name: "Starting", value: "starting" },
        { name: "Pending", value: "pending" },
        { name: "Complete", value: "complete" }
      ],
      academic_class: {
        title: '',
        service_id: '',
        no_of_seats: '',
        start_time: '',
        end_time: '',
        start_date: '',
        end_date: '',
        description: '',
        status: '',
        is_active: true,
      },

      editorConfig: {
        versionCheck: false,
        toolbar: [
            [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' , 'Source','-','Save','NewPage','DocProps','Preview','Print','-','Templates'],
        ]
      }
    }
  },
  validations: {
    academic_class: {
      title: { required },
      service_id: { required },
      no_of_seats: { required, numeric },
      start_time: { required },
      end_time: { required },
      start_date: { required },
      end_date: { required },
      description: { required },
      status: { required },
      is_active: { required }
    }
  },
  mounted() {
    this.getAllServices();
  },
  methods: {
    closeDialog(){
      this.dialog = false;
      this.$v.$reset();
    },
    createClass(){
      this.title = 'Add';
      this.dialog = true;
      this.edit = false;
      this.resetForm();
    },
    editClass(academic_class){
      this.title = "Edit"
      this.dialog = true;
      this.edit = true;
      this.academic_class = academic_class;
    },
    getAllServices(){
      this.loading = true;
      service
          .all()
          .then(response => {
            this.services = response.data.services;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.loading = true;
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function() {
      academic_class
          .create(this.academic_class)
          .then(response =>{
            this.loading = false;
            this.$snotify.success("Academic Class created successfully");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    update() {
      academic_class
          .update(this.academic_class.id,this.academic_class)
          .then(response => {
            this.loading = false;
            this.$snotify.success("Academic Class updated successfully");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    resetForm(){
      this.$v.$reset();
      this.academic_class = {
        title: '',
        service_id: '',
        no_of_seats: '',
        start_time: '',
        end_time: '',
        start_date: '',
        end_date: '',
        description: '',
        is_active: true,
      }
    }
  }
}
</script>

<style scoped>

.vue__time-picker{
  display:initial !important;
}

</style>
