import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class AcademicClassService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/academic-class';
    }

    all(data={}) {
        let url = `${this.#api}/get/all`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    sort(data=[]){
        let url = `${this.#api}/sort`;
        return apiService.post(url, data);
    }

    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    create(data) {
        let url = `${this.#api}`;
        return apiService.post(url, data)
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data)
    }

    delete(id){
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }
    enrolNow(data)
    {
        let url = `${this.#api}/enroll-now`;
        return apiService.post(url, data);
    }
    getAcademicClassStudents(id)
    {
        let url = `${this.#api}/${id}/academic-class-student`
        return apiService.get(url)
    }
    removeAcademicClassStudents(id,userId)
    {
        let url = `${this.#api}/${id}/remove-academic-class-student/${userId}`
        return apiService.get(url)
    }
}