<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Academic Classroom</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Academic Classroom
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn v-if="checkIsAccessible('academic-class', 'create')"
                         @click="createClass()"
                         class="mt-1 btn btn-primary"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add Academic Classroom
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-row>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                      label="Name"
                      v-model="search.title"
                      v-on:keyup.enter="searchClass"
                      @input="search.title = $event !== null ? $event : ''"
                      clearable
                      outlined
                      dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-select v-on:keyup.enter="searchClass"
                            v-model="search.service_id"
                            :items="services"
                            item-text="title" clearable
                            item-value="id"
                            outlined label="Service"
                            dense>
                  </v-select>

                </v-col>

                <v-col cols="12" md="2">
                  <v-menu
                      ref="menuStartDate"
                      v-model="menuStartDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.start_date"
                          label="Start Date"
                          clearable
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.start_date"
                        outlined
                        dense
                        no-title
                        @input="menuStartDate = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="2">
                  <v-menu
                      ref="menuEndDate"
                      v-model="menuEndDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.end_date"
                          label="End Date"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.end_date"
                        outlined
                        dense
                        no-title
                        @input="menuEndDate = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-select
                      label="Status"
                      v-model="search.is_active"
                      v-on:keyup.enter="searchClass"
                      @input="search.is_active = $event !== null ? $event : ''"
                      :items="status"
                      item-text="name"
                      item-value="value"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-btn
                      @click.prevent="searchClass"
                      v-on:keyup.enter="searchClass"
                      class="btn btn-primary"
                      :loading="loading"
                  >
                    <v-icon small outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </div>

            <!-- table -->
            <div class="table-responsive">

              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th><strong>Title</strong></th>
                  <th><strong>Service</strong></th>
                  <th><strong>No Of Seat</strong></th>
                  <th><strong>Start Time</strong></th>
                  <th><strong>End Time</strong></th>
                  <th><strong>Start Date</strong></th>
                  <th><strong>End Date</strong></th>
                  <th><strong>Status</strong></th>
                  <th class="pr-3 text-center"><strong>Action</strong></th>
                </tr>
                </thead>

                <template>
                  <draggable class="w-100" v-model="academic_classes" @change="sort" @start="drag=true" tag="tbody"
                             @end="drag=false">
                    <tr v-for="(item, index) in academic_classes" :key="index">
                      <td>
                        <a @click="editClass(item)"
                           class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                          <i class="fa fa-sort"></i> {{ item.title }}
                        </a>
                      </td>
                      <td>
                        {{ item.service }}
                      </td>
                      <td>
                        <span class="text-dark-65">
                          {{ item.no_of_seats }}
                        </span>
                      </td>
                      <td>
                        <span class="text-dark-65">
                          {{ item.formated_start_time }}
                        </span>
                      </td>
                      <td>
                        <span class="text-dark-65">
                          {{ item.formated_end_time }}
                        </span>
                      </td>
                      <td>
                        <span class="text-dark-65">
                          {{ item.formatted_start_date }}
                        </span>
                      </td>
                      <td>
                        <span class="text-dark-65">
                          {{ item.formatted_end_date }}
                        </span>
                      </td>
                      <td>
                        <span class="badge badge-success text-lg`"
                              v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"
                        >{{ item.is_active ? 'Active' : 'Inactive' }}</span>
                      </td>
                      <td class="pr-0 text-left">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('academic-class', 'edit')">
                                <a class="navi-link" @click="editClass(item)">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-pen</v-icon>
                                  </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('academic-class', 'edit')">
                                <a class="navi-link" @click="openEnrollNow(item.id)">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-cog</v-icon>
                                  </span>
                                  <span class="navi-text">Enroll Now</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('academic-class', 'show')">
                                <a class="navi-link" @click.prevent="viewAcademicClassStudent(item.id)">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-eye</v-icon>
                                  </span>
                                  <span class="navi-text">View</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click.prevent="deleteClass(item.id)" v-if="checkIsAccessible('academic-class', 'edit')">
                                  <span class="navi-icon">
                                      <v-icon color="red darken-2">fas fa-trash-alt</v-icon>
                                  </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>

                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="academic_classes.length == 0">
                      <td class="text-center font-weight-bold px-3" colspan="10">No Data Found</td>
                    </tr>
                  </draggable>
                </template>

              </table>

            </div>
            <!-- table ends -->
            <v-overlay :value="isSorting">
              <v-progress-circular
                  indeterminate
                  color="dark"
              ></v-progress-circular>
            </v-overlay>

          </div>
        </div>
      </div>
      <create-and-update ref="create-and-update" @refresh="getAllClasses"></create-and-update>
      <v-dialog
          v-model="enrollDialog"
          persistent
          max-width="600px"
      >

        <v-card>
          <v-card-title>
            <span class="text-h5">Enroll now</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete item-text="full_name" item-value="id"
                                  :error="$v.enrol.user_id.$error" v-model="enrol.user_id" :items="students"
                                  :search-input.sync="studentSearch"
                                  outlined dense label="Search Student Name*" clearable>
                    <template slot="selection" slot-scope="data">
                      {{ data.item.full_name }}
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>

                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.full_name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                    <template v-slot:label>
                      Select Student
                    </template>
                  </v-autocomplete>
                  <span class="text-danger" v-if="$v.enrol.user_id.$error">Select User</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="closeEnrollNow">
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                @click="enrollNow()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-app>
</template>
<script>
import CreateAndUpdate from "@/view/pages/view/academic-class/CreateAndUpdate";
import draggable from "vuedraggable";
import ServiceService from "@/services/service/ServiceService";
import AcademicClassService from "@/services/academic-class/AcademicClassService";
import UserService from "@/services/user/UserService";
import {required} from "vuelidate/lib/validators";

const user = new UserService();
const academicClass = new AcademicClassService();
const service = new ServiceService();

export default {
  validations: {
    enrol: {
      user_id: {required}
    }
  },
  components: {
    CreateAndUpdate,
    draggable
  },
  data() {
    return {
      academic_classes: [],
      total: null,
      perPage: null,
      page: null,
      services: [],
      loading: false,
      isSorting: false,
      menuStartDate: false,
      menuEndDate: false,
      currentDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      status: [
        {name: 'Active', value: '1'},
        {name: 'Inactive', value: '0'},
      ],

      search: {
        title: '',
        service_id: '',
        start_date: '',
        end_date: '',
        is_active: '',
      },
      enrollDialog: false,
      enrol: {
        academic_class_id: '',
        user_id: '',
      },
      studentSearch: '',
      students: [],


    }
  },
  mounted() {
    this.getAllClasses();
    this.getAllServices();
  },
  methods: {
    createClass() {
      this.$refs['create-and-update'].createClass();
    },
    editClass(academic_class) {
      this.$refs['create-and-update'].editClass(academic_class);
    },
    getAllClasses() {
      this.loading = true;
      academicClass
          .paginate(this.search, this.page)
          .then(response => {
            this.academic_classes = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    getAllServices() {
      service
          .all()
          .then(response => {
            this.services = response.data.services;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    sort() {
      this.isSorting = true;
      academicClass
          .sort(this.academic_classes)
          .then(response => {
            this.isSorting = false;
            this.$snotify.success('Academic Class Sorted !!');
            this.getAllClasses()
          })
          .catch(err => {
            this.isSorting = false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    searchClass() {
      this.getAllClasses();
    },
    openEnrollNow(id) {
      this.enrollDialog = true;
      this.enrol.academic_class_id = id;

    },
    closeEnrollNow() {
      this.students = [];
      this.enrol = {
        academic_class_id: '',
        user_id: '',
      };
      this.enrollDialog = false;
      this.academic_class_students = [];
      this.studentSearch = '';
      this.$v.$reset();
    },
    deleteClass(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            academicClass
                .delete(id)
                .then((response) => {
                  this.getAllClasses()
                  this.$snotify.success("Academic Class Deleted Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
    enrollNow() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        academicClass
            .enrolNow(this.enrol)
            .then(response => {
              if (response.data.message) {
                this.$snotify.error(response.data.message);
              } else {
                this.$snotify.success("Enroll  Successfully ");
              }
              this.closeEnrollNow();
              this.studentSearch = '';
            })
            .catch((err) => {
              this.$snotify.error("Oops something went wrong");
            });
      }
    },

    viewAcademicClassStudent(classId) {
      this.$router.push({
        name: 'academic-class-student',
        params: {classId: classId}
      })
    },


  },
  watch: {
    studentSearch(val) {
      user
          .search(val)
          .then((response) => {
            this.students = response.data.students;
          })
          .catch(err => {
            // console.log(err)
          })
          .finally(() => (this.isLoading = false))
    },
  }
}
</script>